:root {
  --app-primary: #f8ae33;
  --app-black: black;
  --app-white: white;
  --app-grey: #808080;
  --app-grey-bg: #e5e5e5;
  --app-Aboutme-bg: #f7cc7f;
  --app-Serlist-bg: #f3f3f3;
  --app-Serstep-bg: #fbc64e;
}

body {
  margin: 0;
  font-family: "Spartan", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.list-style-none {
  list-style: none !important;
}

.btn.app-primary {
  background-color: var(--app-primary) !important;
}

.contact-btn {
  background-color: var(--app-Serstep-bg);
  font-size: 1rem;
}

.form-control:focus,
.form-select:focus,
.btn.app-primary:focus {
  border-color: #fee97780 !important;
  box-shadow: 0 0 0 0.25rem rgba(247, 223, 124, 0.08) !important;
}


.text-primary {
  color: var(--app-primary) !important;
}

.text-justify {
  text-align: justify !important;
}

.pointer {
  cursor: pointer !important;
}