.serlist-bg {
    background-color: var(--app-Serlist-bg);
}

.serlist-bg.active, .serlist-bg:hover {
    background-color: black;
    color: white;
}

.responsive-list {
    overflow-x: scroll;
    scrollbar-width: none;
}