.testimonials {
    background-color: var(--app-grey-bg) !important;
    padding-block: 7rem;
    /* padding-inline: 5rem; */
}

.testimonial-card {
    background-color: var(--app-white);
    transition: all .2s ease;
}

.testimonial-card:hover {
    background-color: var(--app-primary);
}