.service-container:hover {
    background: var(--app-primary);
}

.service-head {
    font-weight: bold;
    /* border-bottom: 1px solid gray; */
    border-radius: 10px;
    font-size: 1.3rem;
}

.service-container {
    background-color: #EEEEEE;
    /* border: 1px solid gainsboro; */
    border-radius: 10px;

    /* max-width: 100%; */
}