.plans {
  background-color: #362d2c;
  padding-block: 7rem;
  /* padding-inline: 5rem; */
}

.plan-card {
  background-color: var(--app-white);
  transition: all 0.2s ease;
  border-radius: 10px;
  padding-bottom: 4rem;
  position: relative;
  height: 100%;
}

.plan-card:hover {
  background-color: var(--app-primary);
}

.plan-card ul {
  list-style: symbols(cyclic ">");
  color: var(--app-grey);
}

.plan-card:hover .includes,
.plan-card:hover ul {
  color: var(--app-white) !important;
}

.plan-card ul li {
  padding-block: 5px;
}

.price {
  border-radius: 10px;
  background-color: var(--app-grey-bg);
  position: absolute;
  bottom: 1rem;
  width: 90%;
}

.plan-card:hover .price {
  background-color: var(--app-white);
}
