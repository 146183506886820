

.navbar .logo {
    width: 20rem;
}

.navbar .nav-item  {
    padding-inline: 2rem ;
    padding-block: .7rem;
    border-radius: 9px;
    color: var(--app-black);
    text-decoration: none;   
    transition: all .2s ease-in;
    margin-inline: 2px;
}


.navbar .nav-item:hover, .navbar .nav-item.avtive  {
    color: var(--app-primary);
    background-color: black;
} 


/** Invert */
.navbar.invert .nav-item  {
    color: var(--app-white) !important;
}


.navbar.invert .nav-item  {
    color: var(--app-white) !important;
    text-decoration: none;   
}

.navbar.invert .nav-item:hover, .navbar.invert .nav-item.active {
    color: var(--app-black) !important;
}

.navbar.invert .nav-item:hover, .navbar.invert .nav-item.active  {
    color: var(--app-black) !important;
    background-color: var(--app-primary);
}

