.banner {
  background-color: #362d2c;
  height: 90vh;
  background-image: url("/src/Assets/Home/girl-banner.webp"),
    url("/src/Assets/Home/banner-bg-mobile.svg");
  background-repeat: no-repeat no-repeat;
  background-size: 70% 50%, contain;
  background-position: bottom right, right;
}

.banner .title {
  font-size: 2rem;
  line-height: 70px;
  font-weight: bold;
}

.banner .sub-title {
  font-size: 1rem;
}

.text-container {
  margin-top: 20vh;
}

.banner .line {
  width: 50px;
  height: 10px;
  display: inline-block;
  border-radius: 20px;
  background-color: var(--app-primary);
  margin-block: 20px;
}

@media screen and ((min-width: 800px) and (max-height: 1300px) and (orientation: portrait)) {
  .banner {
    background-size: 50% 60%, contain;
  }
}

@media screen and (orientation: landscape) {
  .banner {
    background-image: url("/src/Assets/Home/girl-banner.webp"),
      url("/src/Assets/Home/banner-bg.svg");
    background-size: contain, contain;
    background-position: right;
  }
}
@media screen and (min-width: 1000px) {
  .banner .title {
    font-size: 4rem;
    line-height: 70px;
    font-weight: bold;
  }

  .banner .sub-title {
    font-size: 1.5rem;
  }
}
