.footer {
    background-color: var(--app-black);
}

.footer .logo {
    width: 20rem;
}

.footer .section-title {
    color: var(--app-white);
    text-align: left ;
}

.footer .section ul {
    color: var(--app-white);
    padding: 0;
}

.footer .section ul>li, .footer .section ul>li>a {
    color: var(--app-grey);
    text-decoration: none;
}

.footer .section ul>li:hover, .footer .section ul>li>a:hover {
    color: var(--app-primary);
    text-decoration: none;
}

.footer .section ul>li {
    padding-block: .5rem;
}

.footer .section .social-link-icon-container {
    text-align: left;
    font-size: 2rem;
}

.footer .section .social-link-icon-container>a {
    color: var(--app-white);
    padding-inline: .3rem;
}

.footer .section .social-link-icon-container>a:hover {
    color: var(--app-primary);
}

.footer .section {
    color: var(--app-white);
    text-align: left;
}

.footer .social-link-container {
    padding: 20px;
    background-color: #191919;
    border-radius: 10px;
}