.vision-item-container {
  background-color: black;
  border-radius: 20px;
  transform: translateY(-10%);
}

.vison-host {
  background-color: #362d2c;
  color: white;
}

.vison-host small {
  font-size: 0.8rem;
}

@media screen and (min-width: 600px) {
  .vision-item-container {
    transform: translateY(-10%);
  }
}

@media screen and (min-width: 1000px) {
  .vision-item-container {
    transform: translateY(-50%);
  }

  .vison-host {
    background-color: var(--app-grey-bg);
  }
}
