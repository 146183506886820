.servicestep-container {
  background-color: var(--app-Serstep-bg);
  border: 1px solid white;
  border-radius: 10px;
}
.servicestep-text {
  background-color: var(--app-Serlist-bg);
  border: 1px solid white;
  border-radius: 10px;
  min-height: 140px;
}
.servicestep-container .small {
  font-size: 0.9rem;
}

@media only screen and (min-width: 600px) {
  .servicestep-text {
    border-right: 4px solid var(--app-Serstep-bg);
  }
}
