.evaluation-form select, .evaluation-form input {
    font-size: 1rem !important;
    background-color: #A1A1A114;
}

.logo-container {
    top: 30%;
    left: 7%;
}

.feedbacksection {
    background-color: #F3F3F3;
    border-radius: 20px;
}

#percent:invalid {
    color: red;
}

@media only screen and (min-width: 1200px) {
    .pe-image {
        height: 25vh;
    }
  }
  
  .about-vv {
    box-shadow: -32px 25px 48px rgba(0, 0, 0, 0.09);
    border-radius: 20px;
  }
  